import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/UpdateLayout.js";
import List from 'components/LinkCard/List';
import ListItem from 'components/LinkCard/ListItem';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 {...{
      "id": "a-step-towards-better-performance",
      "style": {
        "position": "relative"
      }
    }}>{`A step towards better performance`}</h3>
    <p>{`Small changes in our React component-library will provide performance 🚀
benefits in the future. At this moment we introduced sideEffects and made some
changes in our icon components to enable better treeshaking (dead code
elimination).`}</p>
    <List my={8} mdxType="List">
  <ListItem title="Migration guide" description="Read more about icon changes" to="/updates/migration" mdxType="ListItem" />
    </List>
    <h3 {...{
      "id": "introduce-hmc-themes-draft",
      "style": {
        "position": "relative"
      }
    }}>{`Introduce hmc themes (draft)`}</h3>
    <p>{`We're extending our theme support with new hmc themes. They are still in draft.
But we wanted to show a sneak peak 👀 on the documentation website already.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`chameleon-theme-hmc-nhb`}</inlineCode>{`: Noordhollands dagblad`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`chameleon-theme-hmc-ld`}</inlineCode>{`: Leidsch Dagblad`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`chameleon-theme-hmc-hd`}</inlineCode>{`: Haarlems Dagblad`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`chameleon-theme-hmc-yc`}</inlineCode>{`: IJmuider Courant`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`chameleon-theme-hmc-ge`}</inlineCode>{`: Gooi- en Eemlander`}</li>
    </ul>
    <p>{`This means you should not use `}<inlineCode parentName="p">{`chameleon-theme-hmc`}</inlineCode>{` because it's meant as a
parent theme.`}</p>
    <h3 {...{
      "id": "deprecating-old-gva--hbvl-themes",
      "style": {
        "position": "relative"
      }
    }}>{`Deprecating old gva & hbvl themes`}</h3>
    <p>{`Since we have `}<inlineCode parentName="p">{`gva-summer-2020`}</inlineCode>{` & `}<inlineCode parentName="p">{`hbvl-summer-2020`}</inlineCode>{` we now officially
deprecated the old gva and hbvl themes. We encourage you to only use the new
themes.`}</p>
    <h3 {...{
      "id": "docs-updates",
      "style": {
        "position": "relative"
      }
    }}>{`Docs updates`}</h3>
    <p>{`Sharing the docs website in a specific brand was difficult before, that's why we
made a change, so the url will update now wherever you go. This makes it more
sharable, and we will remind your last visited brand, so you don't have to
switch every time you come back.`}</p>
    <h3 {...{
      "id": "full-changelog",
      "style": {
        "position": "relative"
      }
    }}>{`Full changelog`}</h3>
    <p>{`Check out the full changelog for more information.`}</p>
    <List my={8} mdxType="List">
  <ListItem title="v1.5.0 changelog" description="Get a full overview of what's changed in the changelog" to="/updates/changelog" mdxType="ListItem" />
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      